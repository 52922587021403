import React, {useEffect, useState} from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Modal} from "react-bootstrap";

import axios from "../../components/api/httpclient";
import {FormattedMessage, useIntl} from "gatsby-plugin-intl"
import imgVehicle from "../../assets/images/vehicle/JUKE_F15-A1-min.png";

const EmailValidation = ({location}) => {
  const intl = useIntl();
  const [modalShow, setModalShow] = useState(false);

  useEffect((event) => {
    sendEmailConfirmation(event);
  },[]);

  const handleClose = () => {
    setModalShow(false);
  };

  const handleShow = () => {
    setModalShow(true);
  };

  const sendEmailConfirmation = (event) =>  {
    if (location.state) {
      const response = axios.post(`/email-confirmation`,
        {
          email: location.state.email,
          first_name: location.state.first_name,
          particle_name: location.state.particle_name,
          last_name: location.state.last_name,
          lang: intl.locale
        }).then(function (response) {
        console.log(response);
      });

      if (event) {
        handleShow();
        event.preventDefault();
      }
    }
  }

  return (
    <Layout>

      <SEO title={intl.formatMessage({ id: "pages_meta_title.sessions.email-validation" })} />

      <div className="white-container mb-4">


        <div className="text-center mb-4">

          <span className="icon-confirm-email fs-50"></span>

        </div>

        <h1 className="text-center"><FormattedMessage id="1_1_2_login_sign_up_step2_please_confirm.title" /></h1>
        <p className="text-center"><FormattedMessage id="1_1_2_login_sign_up_step2_please_confirm.description" values={{ email : location.state ? location.state.email : "" }}/><br />

        </p>

      </div>

      <p className="text-center grey-border"><strong className="dark-grey"><FormattedMessage id="generic.forms.form_mentions.not_received_activation" /></strong><br />
        <FormattedMessage id="generic.forms.form_mentions.check_your_spam" /> <FormattedMessage id="generic.forms.form_mentions.or" /> &nbsp;
        <a href="#" onClick={(event) => sendEmailConfirmation(event)} className="link-nav"><strong><FormattedMessage id="generic.forms.form_buttons.send_new_link" /></strong></a>
      </p>

      <Modal size="md" show={modalShow} onHide={() => handleClose()} animation={false}>
        <Modal.Header className="justify-content-center">
          <h2 className="modal-title text-uppercase flex-grow-1 text-center" id="exampleModalCenterTitle"><FormattedMessage id="1_1_2_login_sign_up_step2_please_confirm.title" /></h2>
          <button type="button" className="close" onClick={() => handleClose()}>
            <span aria-hidden="true"><span className="icon-close"></span></span>
          </button>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p><FormattedMessage id="1_1_2_login_sign_up_step2_please_confirm.description" values={{ email : location.state ? location.state.email : "" }}/></p>
        </Modal.Body>
      </Modal>

    </Layout>
    )
};

export default EmailValidation
